<template>
  <div class="page p-p-2">

    <breadcrumb class="p-mb-4" :model="items"/>


    <DataTable :value="products">
      <Column field="code" header="编码"></Column>
      <Column field="name" header="名称"></Column>
      <Column field="category" header="类别"></Column>
      <Column field="quantity" header="数量"></Column>
    </DataTable>
  </div>
  <Toast/>
</template>

<script>
export default {
  mounted() {
    this.showResume()
  },
  name: "",
  data() {
    return {
      items: [
        {label: '智库推送', to: '/ResumeExamine'},
        {label: '履历审核', to: '/ResumeExamine'}
      ],
      from: {}
    }
  },
  methods: {
    goto() {
      this.$router.push('/ResumeDetails')
    },
    showResume() {
      this.$http.get('/RecordCheck/showRecord').then((res) => {
        console.log(res.data)
        this.from = res.data
      })
    },
    showDetails(item) {
      console.log(item.id)
      this.$router.push('/ResumeDetails?id=' + item.id)
    }
  }
}
</script>

<style scoped>

</style>